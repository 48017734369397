<template>
    <div style="height:100%;padding-top:20px;">
        <a-layout style="height:100%;">
            <a-layout-content style="height:100%;">
                <div class="yj-conten" style="min-height: calc(100% - 48px);background-color:#fff;min-width:max-content;width:calc(100% - 32px);padding-top:20px;">
                    <a-layout style="height:100%;background:#fff;padding:0 20px;">
                        <a-table bordered :columns="columns" rowKey="MaterialID" :data-source="MaterialReadDetail" :pagination="pagination" @change="pagerChange">

                        </a-table>
                    </a-layout>
                </div>
            </a-layout-content>
        </a-layout>
    </div>
</template>
<script type="text/javascript">
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    export default {
        name: "Material_ReadList",
          data() {
              return {

                  MaterialReadDetail: [],

                  columns :[
                      {
                          title: "素材图片",
                          width: 280,
                          align: "center",
                          customRender: (text, item) => {
                              var img = this.Getsrc(item);
                              if (img != null && img != "") {
                                  return <div><img src={img} width="60" height="60" /></div>
                              } else {
                                  return <div><img src="/image/tjwz.png" width="60" height="60" /></div>
                              }
                          }
                      },

                      {
                          title: "素材标题",
                          dataIndex: "Name",
                          width: 280
                      },
                      {
                          title: "阅读量",
                          dataIndex: "ReadCount",
                          width: 280
                      },
                      {
                          title: "拉新量",
                          dataIndex: "NewReadCount",
                          width: 280
                      }
                  ],
                Tablelocale: {
                    emptyText: "暂无数据"
                },
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 1 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 22 },
                    },
                }, config: {
                    rules: [
                        { type: "object", required: true, message: "Please select time!" }
                    ]
                },
                rangeConfig: {
                    rules: [
                        { type: "array", required: true, message: "Please select time!" }
                    ]
                },
                pagination: {
                    total: 0,
                    defaultPageSize: 10,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`
                },
                PublishStatus: "-1",
                MaterialType: "-1",
                  VisterName: "",
                  BeginTime: "",
                  EndTime: "",
                  InputTime: []
            };
        },
        methods: {
            //查询事件
            QueryData() {
                if (this.InputTime.length > 0) {
                    this.BeginTime = util.formatDate(this.InputTime[0]._d);
                    this.EndTime = util.formatDate(this.InputTime[1]._d);
                }
                this.pagination.current = 1;
                this.loadData();
            },
            //时间选择监控事件
            handleChange(value) {

                if (value.length == 0) {
                    this.BeginTime = "";
                    this.EndTime = "";
                }
                this.InputTime = value;
            },
            Getsrc: function (item) {
                var rt = item.ImgTopic == null ? item.Logo : item.ImgTopic;
                if (rt == null) {
                    rt = "#"
                } else {
                    if (rt.indexOf('upload2') < 0) {
                        rt = rt.replace(/upload\//g, 'upload2/');
                    }
                }
                return rt;
            },
            //页面跳转事件
            onPaginationChange(e) {
                this.pagination.current = e
                this.loadData();
            },
            pagerChange: function (item) {
                var self = this;
                self.pagination.current = item.current;
                self.loadData();
            },
            loadData() {
                var self = this;
                console.log(this.$route.query.BeginDate);
                var op = {
                    url: "/MarketingModule/Material/GetPCHotMaterialList",
                    data: {
                        PageIndex: self.pagination.current,
                        BeginDate: this.$route.query.BeginDate,
                        EndDate: this.$route.query.EndDate
                    },
                    OnSuccess: function (res) {                        
                        self.MaterialReadDetail = res.data.Data;
                        console.log(self.MaterialReadDetail);
                        self.pagination.total = res.data.Total;
                        self.pagination.defaultPageSize = res.data.RowCount;
                        self.pagination.current = res.data.PageIndex;
                    }
                };
                http.Post(op);
            },
        },
            mounted() {
            this.loadData();

        }
    };
</script>



